/*     MOBILE VIEW     */

/***********************/
/*     General       */
/***********************/


html, body { 
  font-family: 'EB Garamond', serif;
  min-height: 100vh;
  position: relative;
}

h1, h2, h3, h5, p {
  margin: 0;
}

h1, h2 {
  font-weight: 500;
}

h3, h5 {
  font-weight: 700;
  color: #1459AB;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
}

h4 {
  margin: 5% 0 2% 0;
  color: #BFBFBF;
  font-size: 4em;
  font-weight: 800;
}

p {
  font-weight: 400;
}

a {
  font-family: "Montserrat", sans-serif;
  outline: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 0.8em;
  text-decoration: none;
}

a:hover {
  color: #1459AB;
}

b {
  font-weight: 700;
}

/***********************/
/*     Navigation     */
/***********************/

.navbar img {
  display: none;
}

.desktop-nav {
  display: none;
}

.bm-burger-bars {
  background-color: #373a47;
}

.bm-burger-button {
  position: absolute;
  width: 28px;
  height: 22px;
  right: 20px;
  top: 40px
}

/* clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #1459AB;
}

/* Sidebar wrapper styles */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(255, 255, 255, 0.9);
  padding: 20% 0 0 10% ;
  font-size: 1.3em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: rgba(255, 255, 255, 0.9);
}

.bm-item {
  display: inline-block;
  padding-top: 10%;
}

/***********************/
/*     Header     */
/***********************/

.header-section {
  height: 50vh;
  display: row;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  align-items: center;
}

.header-section > .text > p {
  width: 90vw;
  padding: 6% 5%;
  font-weight: 500;
  color: #A3A3A3;
}

.header-section > .text > h2{
  font-size: 1.2em;
}

.header-section > .text > #header-title {
  width: 14em;
  padding-top: 5%;
}

.header-section > .text > #pronunciation_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 3%;
  font-size: 1em;
}

.header-section > div {
  padding-top: 2em;
  height: 25vh !important;
  width: 80vw !important;
}

/***********************/
/*     Introduction     */
/***********************/

#tagline {
  text-align: center;
  margin: 40% 0 30% 0;
}

#tagline h1 {
  font-size: 1.7em;
}

#tagline img {
  display: none;
}

#everWishYouCould {
  text-align: center;
}

.icon-text-pair {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding-top: 10%;
}

.icon-text-pair p {
  width: 40%;
  padding-top: 2%;
}

#sub-tagline{
  text-align: center;
  margin: 50% 0% 50% 0%;
  font-size: 1.2em;
}

#sub-tagline b {
  font-size: 1.4em;
}

.howIWork-section {
  text-align: center;
  margin: 0 5% 0 5%
}

.howIWork-section img {
  height: 25vh;
  margin: 10% 0 0 0;
}

.howIWork-section .step b {
  color: #1459AB;
}

.howIWork-section .step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.howIWork-section .step p {
  width: 80%;
}

.howIWork-section .result {
  margin: 50px 0 0 0;
}

.howIWork-section .result p {
  font-size: 0.8em;
}

.howIWork-section #explanation {
  padding: 0 10% 0 10%
}

.two-column-wrapper {
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 10px;
  justify-content: space-between;
}


/***********************/
/*     Contact     */
/***********************/

#contact-section {
  margin: 20% 0% 10% 0%
}

#contact-section h3, #contact-section p {
  text-align: center;
}

#contact-subtitle {
  font-size: 0.9em;
}

#required-marker {
  text-align: center;
  font-size: 0.7em;
  color: #1459AB;
  font-weight: 700;
}

#contact-section form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2% 0 0 0;
}

#contact-section .form-item {
  display: grid;
  width: 80%;
  margin: 5% 0 0 0;
}

#contact-section .form-item p {
  color: #bf1650;
  font-size: 0.8em;
  text-align: left;
}

#contact-section .form-item p::before {
  display: inline;
  content: "⚠ ";
}

label {
  padding-bottom: 2px;
  font-weight: 700;
  font-size: 0.8em;
  color: #939393;
}

input, textarea {
  font-size: 14px;
  border-radius: 5px;
  border: 1.5px solid #C4C4C4;
  padding: 10px 15px;
  outline: none
}

textarea {
  font-family: Arial, Helvetica, sans-serif;
}
input[type="submit"] {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  background: #1459AB;
  text-transform: uppercase;
  color: white;
  border: none;
  margin-top: 40px;
  padding: 7px;
  font-size: 10px;
  font-weight: 700;
}


/***********************/
/*     Footer     */
/***********************/

footer {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  position: relative;
  justify-items: center;
  align-items: center;
  bottom: 0;
  font-size: 0.8em;
  height: 11vh;
  margin: 30% 0 10% 0;
}

footer nav ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: space-between;
  width: 60vw;
  padding: 0;
  cursor: pointer;
  margin: 0
}

footer nav ul a {
  padding: 0;
  font-weight: 600;
  font-family: 'EB Garamond', sans-serif;
}

footer img {
  width: 6em;
  padding: 3% 0 3% 0;
}

/***********************/
/*     FAQ PAGE     */
/***********************/

#faq-header {
  text-align: center;
}

#faq-header img {
  width: 10em;
}

#faq-content {
  margin: 20% 5% 0 5%;
}

.faq-item {
  text-align: center;
}

.faq-item h3{
  font-size: 1.1em;
  padding-bottom: 5%;
}

/***********************/
/*     CONTACT PAGE     */
/***********************/

#contact-page-basic-info{
  padding-top: 10%;
  background: url(./images/backgroundLines.svg) center center no-repeat;
  background-size: contain;
}

#contact-page-basic-info {
  text-align: center;
}


/*     TABLET VIEW     */

@media only screen and (min-width: 768px) {
  /***********************/
  /*     General       */
  /***********************/

  h3 {
    font-size: 1.3em;
  }

  a {
    padding-top: 0;
  }

  h4 {
      margin: 5% 0 0 0;
      font-size: 5em;
  }

  #body-wrapper {
    padding-top: 65px;
  }

  /***********************/
  /*     Navigation     */
  /***********************/
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: white;
    position: fixed;
    z-index: 100;
    transition: all 0.3s ease;
  }

  .navbar .desktop-nav {
    display: block;
    padding-right: 4.5em;
  }

  .navbar img {
    display: block;
    width: 3em;
    padding-left: 4.5em;
  }

  .navbar .desktop-nav #nav-item {
    padding-left: 3em;
    font-size: 0.7em;
  }

  /* mobile view hamburger section */
  .left {
    display: none;
  }

  /***********************/
  /*     Header     */
  /***********************/
  .header-section {
    padding-top: 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 4em;
    text-align: left;
  }

  .header-section > .text > p {
    width: 40vw;
    padding: 6% 0;
  }
  
  .header-section > .text > #pronunciation_wrapper {
    justify-content: left;
  }

  .header-section > div {
    padding-top: 0;
    width: 45vw !important;
    height: 35vh !important;
  }

  /***********************/
  /*     Introduction     */
  /***********************/

  #tagline {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: left;
    margin: 15% 0 30% 0;
  }

  #tagline h1 {
    font-size: 2.1em;
    padding-left: 20%;
    width: 60vw;
    align-self: center;
  }

  #tagline img {
    display: block;
    width: 40vw;
  }

  .three-column-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .icon-text-pair {
    padding-top: 30%;
  }

  .icon-text-pair p {
    width: 80%;
    padding-top: 5%;
    font-size: 1.3em;
  }

  .icon-text-pair #icon {
    height: 2.5em;
    width: 2.7em;
  }

  #sub-tagline{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .intro-section #sub-tagline {
    background: url(./images/answersBackground.svg) center center no-repeat;
    background-size: contain;
    margin: 30% 5% 30% 5%;
    min-height: 55vh;
    font-size: 2em;
  }

  .howIWork-section .step p {
    width: 50%;
    font-size: 1.2em;
  }

  .howIWork-section .result {
    margin: 10vh 0 0 0;
    padding: 0 10% 0 10%
  }

  .howIWork-section .result h5 {
    font-size: 1.3em;
    padding-top: 1vh;
  }

  .howIWork-section .result p {
    font-size: 1.1em;
    padding-top: 1vh;
  }
  
  .howIWork-section #sub-tagline{
    font-size: 1.6em;
  }

  .howIWork-section #sub-tagline p {
    width: 75%;
  }

  .howIWork-section #icon {
    height: 2em;
    width: 2.2em;
  }

  .howIWork-section img {
    height: 35vh;
    margin: 5% 0 0 0;
  }

  .howIWork-section #explanation {
    padding: 0 20% 0 20%
  }

  /***********************/
  /*     Contact     */
  /***********************/
  #contact-section {
    margin: 10% 0% 10% 0%
  }
  
  #contact-section .form-item {
    width: 60%;
  }

  #contact-subtitle {
    font-size: 1.1em;
  }

  #required-marker {
    font-size: 0.8em;
  }

  input, textarea {
    font-size: 16px;
  }

  input[type="submit"] {
    padding: 10px;
    font-size: 0.8em;
  }

  /***********************/
  /*     Footer     */
  /***********************/

  footer {
    margin: 30% 0 5% 0;
  }

  footer nav ul {
    width: 35vw;
    font-size: 1.2em;
  }

  /***********************/
  /*     FAQ PAGE     */
  /***********************/

  #faq-header img {
    width: 20em;
  }

  #faq-header h1 {
    font-size: 2.5em;
  }

  #faq-content {
    margin: 10% 5% 0 5%;
  }

  .faq-item h3{
    font-size: 1.2em;
    padding-bottom: 2%;
  }

  .faq-item p {
    font-size: 1.4em;
  }

  /***********************/
  /*     CONTACT PAGE     */
  /***********************/

  #contact-page-basic-info{
    padding-top: 20%;
    padding-bottom: 10%;
  }

  #contact-page-basic-info h3 {
    padding-bottom: 1%;
  }

  #info-item {
    font-size: 1.1em;
  }

  #contact-page-basic-info #icon {
    height: 0.7em;
    width: 1.8em;
  }
}

/* DESKTOP VIEW (1024) */
@media only screen and (min-width: 1024px) {
  /***********************/
  /*     General       */
  /***********************/

  h3 {
    font-size: 1.6em;
  }

  /***********************/
  /*     Navigation     */
  /***********************/

  .navbar img {
    width: 3em;
  }

  .navbar .desktop-nav #nav-item {
    font-size: 0.8em;
  }

  .navbar img {
    width: 4em;
  }

  /***********************/
  /*     Header     */
  /***********************/

  .header-section {
    background-size: 120vw;
    padding-top: 10%;
    margin: 0 4.2em;
  }

  .header-section > .text > p {
    font-size: 1em;
    width: 85%;
    padding: 3% 0 0 0
  }

  .header-section > .text > h2{
    font-size: 1.5em;
  }

  .header-section > .text > #header-title {
    width: 18em;
    padding-top: 3%;
  }

  .header-section > .text > #pronunciation_wrapper {
    font-size: 1.4em;
    padding-top: 2%;
  }
  
  .header-section > div {
    padding-top: 0;
    width: 50vw !important;
    height: 50vh !important;
  }

  /***********************/
  /*     Introduction     */
  /***********************/

  #tagline h1 {
    font-size: 2.7em;
  }

  .icon-text-pair p {
    font-size: 1.5em;
  }

  .intro-section #sub-tagline{
    min-height: 55vh;
    font-size: 2.2em;
    margin: 30% 7% 30% 7%
  }

  .intro-section #sub-tagline p{
    padding-top: 5%;
  }

  .howIWork-section .step p {
    width: 40%;
    font-size: 1.3em;
  }

  .howIWork-section .result h5 {
    font-size: 1.5em;
  }

  .howIWork-section .result p {
    font-size: 1.2em;
    padding: 0 10% 0 10%

  }

  .howIWork-section #explanation {
    padding: 0 25% 0 25%
  }

  .howIWork-section #sub-tagline{
    font-size: 1.8em;
    margin: 30% 10% 30% 10%
  }

  /***********************/
  /*     Contact      */
  /***********************/

  #contact-subtitle {
    font-size: 1.3em;
  }

  #required-marker {
    font-size: 1em;
  }

  label {
    font-size: 1em;
  }

  #contact-section .form-item {
    width: 50%;
    margin: 2% 0 0 0;
  }
  /***********************/
  /*     Footer      */
  /***********************/

  footer {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    margin: 30% 0 0 0;
    font-size: 0.9em;
  }
  
  footer nav ul {
    width: 20vw;
  }
  
  footer img {
    width: 7em;
  }


  /***********************/
  /*     FAQ PAGE     */
  /***********************/

  #faq-header img {
    width: 17em;
  }

  #faq-header h1 {
    font-size: 2.5em;
  }

  #faq-content {
    margin: 10% 13% 0 13%;
  }

  .faq-item h3{
    font-size: 1.1em;
    padding-bottom: 2%;
    text-transform: uppercase;
  }

  .faq-item p {
    font-size: 1.1em;
  }

  /***********************/
  /*     CONTACT PAGE     */
  /***********************/

  #contact-page-basic-info{
    padding-top: 20%;
    padding-bottom: 10%;
  }

  #contact-page-basic-info h3 {
    padding-bottom: 1%;
  }

  #info-item {
    font-size: 1.3em;
  }

  #contact-page-basic-info #icon {
    height: 0.8em;
    width: 2em;
  }
}


/* DESKTOP VIEW (1300) */
@media only screen and (min-width: 1300px) {
  /***********************/
  /*     General       */
  /***********************/

  h4 {
    font-size: 7em;
  }

  /***********************/
  /*     Navigation     */
  /***********************/

  .navbar img {
    width: 3.3em;
    padding-left: 9em;
  }

  .navbar .desktop-nav #nav-item {
    font-size: 0.9em;
  }

  .navbar .desktop-nav {
    padding-right: 8em;
  }

  .navbar img {
    width: 4.5em;
    padding-left: em;
  }

  /***********************/
  /*     Header     */
  /***********************/

  .header-section {
    background-size: 120vw;
    padding-top: 8%;
    margin: 0 8em;
  }

  .header-section > .text > p {
    font-size: 1.3em;
  }

  .header-section > .text > h2{
    font-size: 1.9em;
  }

  .header-section > .text > #header-title {
    width: 22em;
    padding-top: 4%;
  }

  .header-section > .text > #pronunciation_wrapper {
    font-size: 1.5em;
  }

  .header-section > div {
    width: 45vw !important;
    height: 55vh !important;
  }

  /***********************/
  /*     Introduction     */
  /***********************/

  #tagline h1 {
    font-size: 3.2em;
  }

  #tagline {
    margin: 20% 0 20% 0;
  }

  .three-column-wrapper {
    padding: 0 10% 0 10%;
  }
  
  .icon-text-pair p {
    font-size: 1.7em;
  }

  .intro-section #sub-tagline{
    margin: 20% 0% 20% 0%
  }

  .howIWork-section .step p {
    width: 35%;
    font-size: 1.7em;
  }
  
  .howIWork-section .two-column-wrapper {
    padding: 0 5% 0 5%
  }

  .howIWork-section .result {
    padding-top: 5%;
  }

  .howIWork-section .result h5 {
    font-size: 1.7em;
  }

  .howIWork-section .result p {
    font-size: 1.5em;
    padding: 1% 12% 0 12%
  }

  .howIWork-section #explanation {
    padding: 0 20% 0 20%
  }

  .howIWork-section #sub-tagline{
    font-size: 2em;
    margin: 30% 18% 30% 18%
  }

  .howIWork-section #icon {
    height: 3em;
    width: 3.2em;
  }

  /***********************/
  /*     Contact      */
  /***********************/

  #contact-subtitle {
    font-size: 1.5em;
  }

  #required-marker {
    font-size: 1.2em;
  }

  label {
    font-size: 1.2em;
  }

  input, textarea {
    font-size: 16px;
    padding: 20px 20px;
  }

  #contact-section .form-item p {
    font-size: 1.2em;
  }

  input[type="submit"] {
    font-size: 1em;
  }
  
  /***********************/
  /*     Footer      */
  /***********************/
  footer {
    font-size: 1em;
  }

  /***********************/
  /*     FAQ PAGE     */
  /***********************/

  #faq-content {
    margin: 10% 15% 0 15%;
  }

  #faq-header img {
    width: 19em;
  }

  #faq-header h1 {
    font-size: 3em;
  }

  .faq-item h3{
    font-size: 1.5em;
    padding-bottom: 2%;
  }

  .faq-item p {
    font-size: 1.5em;
  }

  /***********************/
  /*     CONTACT PAGE     */
  /***********************/

  #contact-page-basic-info{
    padding-top: 15%;
  }

  #info-item {
    font-size: 1.5em;
  }
}
